import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
//import useForm from 'react-hook-form'
import { Button, Form } from 'reactstrap';
import * as base from "./global";
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
//https://stackoverflow.com/questions/41296668/reactjs-form-input-validation
import ActivityIndicator from 'react-activity-indicator'
//npm install react-activity-indicator
import 'react-activity-indicator/src/activityindicator.css'
import { Offline, Online } from "react-detect-offline";

import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert } from 'react-bootstrap'

export class Continuewithteacher extends Component {

  constructor() {
    super();
    this.register = this.register.bind(this);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      Password: '',
      isLoading: false,
      loaderfile: 'notloading',
      fields: {},
      errors: {},
      loggdin: 'stop',
      counter: 0, showpassordfirsttxt: false, showpasswordsecondtxt: false
    }


  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //  alert(this.state.fields["email"]);
    //Name
    if (!this.state.fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password Reqired";
    }
    if (!this.state.fields["fullname"]) {
      formIsValid = false;
      errors["fullname"] = "Full Name Required";
    }
    if (!this.state.fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email Required";
    }
    if (!this.state.fields["reentrypassword"]) {
      formIsValid = false;
      errors["reentrypassword"] = "Re Entry required";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  register() {
    if (this.handleValidation()) {
      this.setState({ loaderfile: 'loading', isLoading: true }) ///Adminloginareacheck  adminlogin Adminloginsetup
      if (this.state.fields['reentrypassword'] == this.state.fields['reentrypassword']) {
        axios.post(base.BASE_URL + '/continuewithteacher', {
          email: this.state.fields["email"],
          password: this.state.fields["password"],
          fullname: this.state.fields["fullname"]
        })
          .then((response) => {
            if (response.data.status == "save") {
              localStorage.setItem("token", response.data.access_token);
              localStorage.setItem("vendoremailaddress", this.state.fields["email"]);
              localStorage.setItem("usersrole", 'Teacher')
              localStorage.setItem("fullname", this.state.fields["fullname"]);
              //  localStorage.setItem("examid", response.data.examid);
              //  localStorage.setItem("classname", response.data.classname);
              //  localStorage.setItem("sectionname", response.data.sectionname);


              this.setState({ loggdin: 'start', loaderfile: 'notloading', isLoading: false })

            }
            else if (response.data.status == "userexits") {
              this.setState({ loggdin: 'stop', isLoading: false })
              alert("Email Address Already Exits, Please Try with different email");
            }
            else if (response.data == "Unauthorized") {
              this.setState({ loggdin: 'stop', isLoading: false })
              alert("Invalid Email and Password");
            }
            else if (response.data == "Unauthorized f") {

              this.setState({ loggdin: 'stop', isLoading: false })
              alert("Invalid Email and Password");

            }


          }, (error) => {
            console.log(error);
            alert(error)
          });

      }
      else {
        alert("Password is not Match")
      }

    } else {
      // alert("Form has errors.")
    }


  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handleClickShowPassword = () => {
    // setValues({ ...values, showPassword: !values.showPassword });
    // alert(this.state.showPassword)
    if (this.state.showpassordfirsttxt == false) {
      this.setState({
        showpassordfirsttxt: true
      })
    }
    else {
      this.setState({
        showpassordfirsttxt: false
      })
    }

  };
  handleClickShowPasswordtwo = () => {
    // setValues({ ...values, showPassword: !values.showPassword });
    // alert(this.state.showPassword)
    if (this.state.showpasswordsecondtxt == false) {
      this.setState({
        showpasswordsecondtxt: true
      })
    }
    else {
      this.setState({
        showpasswordsecondtxt: false
      })
    }

  };
  render() {

    if (this.state.loggdin == 'start') {
      return <Redirect to="/TeacherSchool" />
    }
    return (
      <div>
        <Header />
        <div className="container" style={{ marginTop: 86, padding: 5 }}>

                  {
                    this.state.isLoading == true ?
                      <ActivityIndicator />
                      :
                      <div className="row">
                        <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                        <div className="col-lg-6">
                          <div className="p-5">
                            <div className="text-center">

                              <h1 className="h4 text-gray-900 mb-4">Create Teacher Account</h1>
                            </div>
                            <form className="user text-center">


                              <div className="form-group">
                                <input type="text" className="form-control logintext"
                                  id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Full Name ."
                                  onChange={this.handleChange.bind(this, "fullname")} value={this.state.fields["fullname"]}
                                />
                                <span style={{ color: "red" }}>{this.state.errors["fullname"]}</span>
                              </div>

                              <div className="form-group">
                                <input type="email" className="form-control logintext"
                                  id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address..."
                                  onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}
                                />
                                <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                              </div>
                              <div className="form-group">
                                <div style={{ display: 'flex' }}>
                                  <input
                                    type={this.state.showpassordfirsttxt ? "text" : "password"}
                                    onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]}
                                    className="form-control logintext" placeholder="Password"
                                    endAdornment={
                                      <InputAdornment position="end">

                                      </InputAdornment>
                                    }
                                  />
                                  <IconButton
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                    style={{ width: 50 }}
                                  >
                                    {this.state.showpassordfirsttxt ? <VisibilityIcon /> : <VisibilityOff />}
                                  </IconButton>
                                </div>

                                <span style={{ color: "red" }}>{this.state.errors["password"]}</span>
                              </div>

                              <div className="form-group">

                                <div style={{ display: 'flex' }}>
                                  <input
                                    type={this.state.showpasswordsecondtxt ? "text" : "password"}
                                    onChange={this.handleChange.bind(this, "reentrypassword")} value={this.state.fields["reentrypassword"]}
                                    className="form-control logintext" placeholder="Re Entry Password"
                                    endAdornment={
                                      <InputAdornment position="end">

                                      </InputAdornment>
                                    }
                                  />
                                  <IconButton
                                    onClick={this.handleClickShowPasswordtwo}
                                    onMouseDown={this.handleMouseDownPassword}
                                    style={{ width: 50 }}
                                  >
                                    {this.state.showpasswordsecondtxt ? <VisibilityIcon /> : <VisibilityOff />}
                                  </IconButton>
                                </div>

                                <span style={{ color: "red" }}>{this.state.errors["reentrypassword"]}</span>
                              </div>

                              <Button type="button" className="btn btn-primary btn-user btn-block teacherlogin" onClick={this.register}>
                                Submit
                              </Button>

                            </form>

                            <div className="text-center" style={{ padding: 20 }}>
                              <Link className="small forgetlink" to="/Login">Already have account. Login ?</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                  }
               </div>
              </div>
      )

  }
}

export default Continuewithteacher