import React, { useState, useEffect} from 'react';
import { Offline, Online } from "react-detect-offline";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert } from 'react-bootstrap'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Studentheader from './Studentheader';
import Footer from '../Footer';
import { Link, useHistory } from 'react-router-dom';
import Recentactivity from './Recentactivity'
import Coachingdisplay from './Coachingdisplay'

import * as base from "../global";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import Examimage from './Examimage'
import LoadingSpinner from '../library/LoadingSpinner'


const Studentdashboard = (props) => {
  const history = useHistory()
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [users, setUsers] = useState([]);
  const [isloading, setIsloading] = useState(true);
   const [studentid, setStudentid] = useState(localStorage.getItem('studentid'))
  const [classname, setClassname] = useState(localStorage.getItem('className')) 
  const [groupname, setGroupname] = useState(localStorage.getItem('groupname')) 
  const [token, setToken] = useState('')
  const [searchtext, setSearchtext] = useState('');
  const [checkpayment, setCheckpayment] = useState('');
    const [checktrial, setChecktrial] = useState('');
  const [errorsearch, setErrorsearch] = useState(false);

  let paymenthistory = require('../course/data.json');
  const filtered = paymenthistory.filter(data => {
    return data.classname === localStorage.getItem('className') && data.versionname === localStorage.getItem('versionname') && data.groupname === localStorage.getItem('groupname');

  });



const getData = () =>{
  //alert('dddd')
  setIsloading(true) 
  console.log(base.BASE_URL + '/getstudentwiseexam/' + studentid + '/Active')
    axios.get(base.BASE_URL + '/getstudentwiseexam/' + studentid + '/Active', {
        headers: {
            authorization: `bearer ${localStorage.getItem('token')}`
        }
    }).then(res => {
        setUsers(res.data);
      
    window.scrollTo(0, 0);

    });
   // setIsloading(false) 
}
const getDatacheckpayment = () =>{
    console.log('check payment....' + base.BASE_URL + '/getDatacheckpayment/' + studentid)
    axios.get(base.BASE_URL + '/getDatacheckpayment/' + studentid , {
        headers: {
            authorization: `bearer ${localStorage.getItem('token')}`
        }
    }).then(res => {
        //alert(res.data);
        setCheckpayment(res.data);
       setIsloading(false);

    });
   
}
 const checktrialdata = () => {
    console.log(base.BASE_URL + '/checktrialdata/' + studentid )
    setIsloading(true);
    axios.get(base.BASE_URL + '/checktrialdata/' + studentid, {
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`
      }
    }).then(res => {
      console.log(res.data);
      setChecktrial(res.data);
      setIsloading(false);

    });
  }


useEffect(() => {
  gettoken()
 // getDatacheckpayment();
}, [])

const gettoken =() => {
  
  try{
    localStorage.setItem("token", token);
    localStorage.setItem("studentid", studentid);
    localStorage.setItem("fullname", '');
    localStorage.setItem("className", classname);
    localStorage.setItem("groupname", groupname);
   // setIsloading(true) //ownexambystudent
    //getData();
    getDatacheckpayment();
    checktrialdata();
   // setIsloading(false) //ownexambystudent
  }
  catch(error){

  }
 
}
const finddata=() => {
 // alert(searchtext)
 if(searchtext == "")
 {
  setErrorsearch(true);
  return;
 }
 else
 {
  history.push("/Studentfindsubject/" + searchtext  + '/1/12')
 }
 

}


  return (
    <div style={{ backgroundColor: '#f9f9f9', width: '100%' }}>
      <Studentheader />
      <Row style={{ marginTop: 120 }}>

      </Row>

      {
        isloading ?
          <LoadingSpinner />
          :
          checkpayment == '' ?
            filtered ?
              filtered.map(employee => {
                const datap = employee.package;
                return (
                  <Container style={{ padding: 20 }}>
                  <Row key={employee.id}>
                    {
                      //<Link className='packagebutton' to={"/Viewcart/" + props.match.params.version + '/' + props.match.params.classnames + '/' + props.match.params.singleclass + '/' + d.packageid}>
                      datap.map((d) => {
                        let packageid = ''; //encrypt(d.packageid);
                        return <div class="card col-md-3 box-shadow" style={{ padding: 5, marginRight: 5, marginLeft: 5,
                        marginTop: 10, marginBottom: 10 }}>
                            <div class="card-header">
                              <h4 class="my-0 font-weight-normal">{d.packagename}</h4>
                            </div>
                            <div class="card-body">
                              <h1 class="card-title pricing-card-title">{d.amount} <small class="text-muted"></small></h1>
                              <ul class="list-unstyled mt-3 mb-4">
                                <li>{d.description}</li>
                             
                              </ul>
                               {
                                checktrial > 0 && d.amount=='0' ?
                                <div class="btn">Expaired</div> 
                              :
                              <Link 
                              to={"/Admissiontestpayment/" + d.packageid }
                               style={{ backgroundColor: 'rgb(108, 66, 152)',
                            width: '100%',
                            height: 40, color: '#ffffff', borderRadius: 15 }} class="btn">Buy Now</Link>
                              }
                            
                            </div>
                          </div>
                        
                      })
                    }
                  </Row>
                  </Container>
                );
              })
              :
            ''
            :
            ''
      }
      {
        isloading ? 
        <LoadingSpinner />
        :
        checkpayment > 0 ?
        <Coachingdisplay />
        :
        ''
      }
    </div>
  );
}


export default Studentdashboard;