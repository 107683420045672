import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
//import useForm from 'react-hook-form'
import { Button, Form } from 'reactstrap';
import { Progress } from 'reactstrap';
import * as base from "./global";
import axios from 'axios';
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Topbar from '../Layout/Topbar'
//https://stackoverflow.com/questions/41296668/reactjs-form-input-validation
import ActivityIndicator from 'react-activity-indicator'
//npm install react-activity-indicator
import 'react-activity-indicator/src/activityindicator.css'
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Editor } from "react-draft-wysiwyg"; // this is for text area editor
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";// this is for text area editor
import { convertToHTML } from 'draft-convert';

/* npm install @material-ui/core
npm install @material-ui/icons */
//import ImageIcon from '@material-ui/icons/ImageIcon';
import IconButton from '@material-ui/core/IconButton';
import ImageIcon from '@mui/icons-material/Image';
import { addStyles, EditableMathField, StaticMathField } from 'react-mathquill'



import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const  modules  = {
    toolbar: [
      //  [{ font: [] }],
     //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
     //   ["bold", "italic", "underline", "strike"],
     //   [{ color: [] }, { background: [] }],
        [{ script:  "sub" }, { script:  "super" }],
      //  ["blockquote", "code-block"],
      //  [{ list:  "ordered" }, { list:  "bullet" }],
      //  [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
      //  ["link", "image", "video"],
      //  ["clean"],
    ],
};


export class Quizsetuptwo extends Component {

    constructor(props) {
        super(props);
        this.register = this.register.bind(this);
        this.handlecountry = this.handlecountry.bind(this);
        this.handlestate = this.handlestate.bind(this);
        this.handlename = this.handlename.bind(this);
        this.handlesubject = this.handlesubject.bind(this);
        this.handleQuestiontype = this.handleQuestiontype.bind(this);

        this.handleAtype = this.handleAtype.bind(this);
        this.handleBtype = this.handleBtype.bind(this);
        this.handleCtype = this.handleCtype.bind(this);
        this.handleDtype = this.handleDtype.bind(this);

        this.handleAtitle = this.handleAtitle.bind(this);
        this.handleBtitle = this.handleBtitle.bind(this);
        this.handleCtitle = this.handleCtitle.bind(this);
        this.handleDtitle = this.handleDtitle.bind(this);

        this.handleDtype = this.handleDtype.bind(this);
        this.handleAnswer = this.handleAnswer.bind(this);
        this.handlewrittinganswer = this.handlewrittinganswer.bind(this);
        this.handlequestiontitle = this.handlequestiontitle.bind(this);
        this.testmath = this.testmath.bind(this)

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeA = this.handleChangeA.bind(this)
        this.handleChangeB = this.handleChangeB.bind(this)
        this.handleChangeC = this.handleChangeC.bind(this)
        this.handleChangeD = this.handleChangeD.bind(this)

        this.state = {
            name: '',
            address: '',
            email: '',
            phone: '',
            id: '',
            website: '',
            isLoading: false,
            loaderfile: 'notloading',
            fields: {},
            errors: {},
            loggdin: 'stop',
            counter: 0,
            states: '',
            country: '',
            statelist: [],
            name: '',
            schoolcollegetype: '',
            schoolcollegename: '',
            subjectlist: '',
            schoolcollegesubjectlist: [],
            seriallist: [],
            automemberid: this.props.match.params.id,
            examid: this.props.match.params.ids,
            examname: this.props.match.params.idss,
            questiontype:'',
            questionimageupload_loader: false,
            uploadPercentage: 0,
            questiontitle:'',
            Answer:'',
            writtinganswer:'',
            editorState: EditorState.createEmpty(),
            editorHtml: '', theme: 'snow',
            editorHtmlA: '',
            editorHtmlB: '',editorHtmlC: '',editorHtmlD: ''
            
        }
        this.inputReference = React.createRef();
        this.inputReferenceA = React.createRef();
        this.inputReferenceB = React.createRef();
        this.inputReferenceC = React.createRef();
        this.inputReferenceD = React.createRef();

    }

    componentDidMount() {
      /*   axios.get(base.BASE_URL + '/quizautoid').then(res => {
            this.setState({ seriallist: res.data, automemberid: res.data.status });
            console.log(res.data)
        }); */
    }
    handleChange (html) {
        this.setState({ editorHtml: html });
    }
    handleChangeA (html) {
        this.setState({ editorHtmlA: html });
    }
    handleChangeB (html) {
        this.setState({ editorHtmlB: html });
    }
    handleChangeC (html) {
        this.setState({ editorHtmlC: html });
    }
    handleChangeD (html) {
        this.setState({ editorHtmlD: html });
    }
handlequestiontitle(event){
    this.setState({
        questiontitle: event.target.value
    })
}
handlewrittinganswer(event){
    this.setState({
        writtinganswer: event.target.value
    })
}
handleAtitle(event){
    this.setState({
        Atitle: event.target.value
    })
}
handleBtitle(event){
    this.setState({
        Btitle: event.target.value
    })
}
handleCtitle(event){
    this.setState({
        Ctitle: event.target.value
    })
}
handleDtitle(event){
    this.setState({
        Dtitle: event.target.value
    })
}
handleAnswer(event)
{
    this.setState({
        Answer: event.target.value
    })
}
    handlecountry(event) {
        this.setState({
            schoolcollegetype: event.target.value
        })

        this.setState({ statelist: [] });
        ///////////////////////////////
        axios.get(base.BASE_URL + '/getschoollist/' + event.target.value).then(result => {
            this.setState({ statelist: result.data });
        });
        ///////////////////////////////
    }
    handlesubject(event) {
        this.setState({
            subjectname: event.target.value
        })



    }
    handlestate(event) {
        this.setState({
            schoolcollegename: event.target.value
        })
        alert(event.target.value)
        this.setState({ schoolcollegesubjectlist: [] });
        ///////////////////////////////
        axios.get(base.BASE_URL + '/getparmetersubjectlist/' + this.state.schoolcollegetype + '/' + event.target.value).then(result => {
            this.setState({ schoolcollegesubjectlist: result.data, automemberid: result.data[0].status });

        });
        ///////////////////////////////
    }
    handlename(event) {
        this.setState({
            name: event.target.value
        })
    }
    handleQuestiontype(event)
    {
        this.setState({
          questiontype  : event.target.value
        })
    }
    handleAtype(event)
    {
        this.setState({
          Atype  : event.target.value
        })
    }
    handleBtype(event)
    {
        this.setState({
          Btype  : event.target.value
        })
    }
    handleCtype(event)
    {
        this.setState({
          Ctype  : event.target.value
        })
    }
    handleDtype(event)
    {
        this.setState({
          Dtype  : event.target.value
        })
    }
    testmath(){

        alert(this.state.editorHtml)
        //alert(this.state.editorState)
       // alert(this.state.editorState.getCurrentContent().getPlainText());
   //const currentContentAsHTML = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));

       // alert(currentContentAsHTML);
    }
    register() {

        if (this.state.automemberid == "" || this.state.Answer == "") {
            alert('Insert the Required Fields')
        }
        else {
            this.setState({ loaderfile: 'loading' }) ///Adminloginareacheck  adminlogin Adminloginsetup


            axios.post(base.BASE_URL + '/questionsaveall', {
                automemberid: this.state.automemberid, //EditorState.createWithContent
                //questiontitle: this.state.editorState.getCurrentContent().getPlainText(), //this.state.questiontitle, convertToRaw(this.state.editorState.getCurrentContent())
                questiontitle: this.state.editorHtml, //JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())), //convertToHTML(this.state.editorState.getCurrentContent()),
                questiontype: this.state.questiontype,
                Atype: this.state.Atype,
                Atitle: this.state.editorHtmlA,
                Btype: this.state.Btype,
                Btitle: this.state.editorHtmlB,
                Ctype: this.state.Ctype,
                Ctitle: this.state.editorHtmlC,
                Dtype: this.state.Dtype,
                Dtitle: this.state.editorHtmlD,
                Answer: this.state.Answer,
                writtinganswer: this.state.writtinganswer,
                quiztype: 'MCQ'
                
            }, {
                headers: {
                    authorization: `bearer ${localStorage.getItem('token')}`
                }
            })
                .then((response) => {
                      //  alert(response.data.status)
                    if (response.data.status == "found") {
                        this.setState({ loggdin: 'start', loaderfile: 'notloading' })

                    }
                    else if (response.data == "notfound") {
                        alert("x");
                    }


                }, (error) => {
                    console.log(error);
                });


        }

        /////////////////////////////////////////////////////////////////////

    }
    fileSelectquestionimage = event => {
if(this.state.questiontype == "" || this.state.automemberid == "")
{
  alert('Insert the Question Type')
}
else if(event.target.files[0] == "")
{
    alert('Image Required')
}   
else
{
////////////////////////////////////////
this.setState({ questionimage: event.target.files[0],  questionimageupload_loader: true })
console.log(event.target.files[0])
// image preview
var file = event.target.files[0];
var reader = new FileReader();
var url = reader.readAsDataURL(file);

reader.onloadend = function (e) {
    this.setState({
        preview: [reader.result]
    })
}.bind(this);
console.log(url) // Would see a path?
// end image previou
//////////upload
const options = {
    onUploadProgress: (progressEvent) => {
        const {loaded, total} = progressEvent
        let percent = Math.floor(loaded * 100 / total)
        if(percent < 100)
        {
            this.setState({
                uploadPercentage: percent
            })
        }

    },
}


const fd = new FormData();
fd.append('profileImg', event.target.files[0], event.target.files[0].name);
fd.append('autoincrement', this.state.automemberid);
fd.append('questiontype', this.state.questiontype);
axios.post(base.BASE_URL + '/questionimageupload', fd, options
).then(res => {
    // alert(res.message);
    if (res.status == '200') {

        this.setState({ questionimageupload_loader: false, uploadPercentage: 100 }, ()=>{
            setTimeout(() =>{
                this.setTimeout({
                        uploadPercentage: 0
                }, 1000)
            })
        })
    }
    else {
        this.setState({ questionimageupload_loader: false })
        alert('Failed To Upload')
    }
    console.log(res);
}
);

///////////////////////////////////////
}    
        ////////////
    }


    fileSelectA = event => {
        if(this.state.Atype == "" || this.state.automemberid == "")
        {
          alert('Insert the Question A Type')
        }   
        else
        {
        ////////////////////////////////////////
        this.setState({ questionAimage: event.target.files[0],  questionimageupload_loader: true })
        console.log(event.target.files[0])
        // image preview
        var file = event.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        
        reader.onloadend = function (e) {
            this.setState({
                previewA: [reader.result]
            })
        }.bind(this);
        console.log(url) // Would see a path?
        // end image previou
        //////////upload
        const fd = new FormData();
        fd.append('profileImg', event.target.files[0], event.target.files[0].name);
        fd.append('autoincrement', this.state.automemberid);
        fd.append('Atype', this.state.Atype);
        axios.post(base.BASE_URL + '/Aimageupload', fd
        ).then(res => {
            // alert(res.message);
            if (res.status == '200') {
        
                this.setState({ questionimageupload_loader: false })
            }
            else {
                this.setState({ questionimageupload_loader: false })
                alert('Failed To Upload')
            }
            console.log(res);
        }
        );
        
        ///////////////////////////////////////
        }    
                ////////////
            }    
/////////////////////////////////////////////////////////////////////////


fileSelectB = event => {
    if(this.state.Btype == "" || this.state.automemberid == "")
    {
      alert('Insert the Question B Type')
    }   
    else
    {
    ////////////////////////////////////////
    this.setState({ questionBimage: event.target.files[0],  questionimageupload_loader: true })
    console.log(event.target.files[0])
    // image preview
    var file = event.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    
    reader.onloadend = function (e) {
        this.setState({
            previewB: [reader.result]
        })
    }.bind(this);
    console.log(url) // Would see a path?
    // end image previou
    //////////upload
    const fd = new FormData();
    fd.append('profileImg', event.target.files[0], event.target.files[0].name);
    fd.append('autoincrement', this.state.automemberid);
    fd.append('Btype', this.state.Btype);
    axios.post(base.BASE_URL + '/Bimageupload', fd
    ).then(res => {
        // alert(res.message);
        if (res.status == '200') {
    
            this.setState({ questionimageupload_loader: false })
        }
        else {
            this.setState({ questionimageupload_loader: false })
            alert('Failed To Upload')
        }
        console.log(res);
    }
    );
    
    ///////////////////////////////////////
    }    
            ////////////
        }    
//////////////////////////////////////////////////////////////////////////

fileSelectC = event => {
    if(this.state.Ctype == "" || this.state.automemberid == "")
    {
      alert('Insert the Question C Type')
    }   
    else
    {
    ////////////////////////////////////////
    this.setState({ questionCimage: event.target.files[0],  questionimageupload_loader: true })
    console.log(event.target.files[0])
    // image preview
    var file = event.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    
    reader.onloadend = function (e) {
        this.setState({
            previewC: [reader.result]
        })
    }.bind(this);
    console.log(url) // Would see a path?
    // end image previou
    //////////upload
    const fd = new FormData();
    fd.append('profileImg', event.target.files[0], event.target.files[0].name);
    fd.append('autoincrement', this.state.automemberid);
    fd.append('Ctype', this.state.Ctype);
    axios.post(base.BASE_URL + '/Cimageupload', fd
    ).then(res => {
        // alert(res.message);
        if (res.status == '200') {
    
            this.setState({ questionimageupload_loader: false })
        }
        else {
            this.setState({ questionimageupload_loader: false })
            alert('Failed To Upload')
        }
        console.log(res);
    }
    );
    
    ///////////////////////////////////////
    }    
            ////////////
        }    




////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////

fileSelectD = event => {
    if(this.state.Dtype == "" || this.state.automemberid == "")
    {
      alert('Insert the Question C Type')
    }   
    else
    {
    ////////////////////////////////////////
    this.setState({ questionDimage: event.target.files[0],  questionimageupload_loader: true })
    console.log(event.target.files[0])
    // image preview
    var file = event.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    
    reader.onloadend = function (e) {
        this.setState({
            previewD: [reader.result]
        })
    }.bind(this);
    console.log(url) // Would see a path?
    // end image previou
    //////////upload
    const fd = new FormData();
    fd.append('profileImg', event.target.files[0], event.target.files[0].name);
    fd.append('autoincrement', this.state.automemberid);
    fd.append('Dtype', this.state.Dtype);
    axios.post(base.BASE_URL + '/Dimageupload', fd
    ).then(res => {
        // alert(res.message);
        if (res.status == '200') {
    
            this.setState({ questionimageupload_loader: false })
        }
        else {
            this.setState({ questionimageupload_loader: false })
            alert('Failed To Upload')
        }
        console.log(res);
    }
    );
    
    ///////////////////////////////////////
    }    
            ////////////
        }    

////////////////////////////////////////////////////////////////////////
//onChangeQuestionTitletextarea = (editorState) => this.setState({editorState});


/* onChangeQuestionTitletextarea = (editorState) => {
    const contentState = editorState.getCurrentContent();
    console.log('content state', convertToRaw(contentState));
    this.setState({
      editorState,
    });
    
} */

/* onChangeQuestionTitletextarea() {
        alert(quill.root.innerHTML)
}
 */
onChangeQuestionTitletextarea(event){
//    alert(event.target.value)
  /*   this.setState({
        Btitle: event.target.value
    }) */
}

  handleClickQuestionimage = () => {
    //this.fileSelectquestionimage();
    //document.getElementById('questionfile').click();
    this.inputReference.current.click()
  };
  handleClickAimage = () => {
    //this.fileSelectquestionimage();
    //document.getElementById('questionfile').click();
    this.inputReferenceA.current.click()
  };
  handleClickBimage = () => {
    //this.fileSelectquestionimage();
    //document.getElementById('questionfile').click();
    this.inputReferenceB.current.click()
  };
  handleClickCimage = () => {
    //this.fileSelectquestionimage();
    //document.getElementById('questionfile').click();
    this.inputReferenceC.current.click()
  };
  handleClickDimage = () => {
    //this.fileSelectquestionimage();
    //document.getElementById('questionfile').click();
    this.inputReferenceD.current.click()
  };

    render() {
        // alert(this.state.loggdin);
        const {uploadPercentage} = this.state;
        if (this.state.loggdin == 'start') {
            return <Redirect to={"/Quizsetuplist/" + this.state.examid + '/' + this.state.examname} />
        }

        return (
            <div>
                <Header /> {/* Slider Menu */}
                <Container style={{ marginTop: 85 }}>
                
                                <div className="container-fluid">
                                    {/* Page Heading */}
                                    <div class="card position-relative" style={{ backgroundColor: '#012060' }}>
                                        <div class="card-header py-3">
                                            <div className="form-header">
                                                <h3 class="m-0 font-weight-bold text-primary">Quiz Details {/* - Quiz ID: {this.state.automemberid} */}</h3>
                                            </div>
                                           {/*  <p style={{ color: '#000' }}>Exam Name : {this.state.examname}</p> */}

                                        </div>
                                        <div class="card-body">

                                            <div className="row">

                                                <div className='col-md-12'>


                                                    <form className="user">

                                                        <div class="form-content">
                                                            <div>
                                                                {
                                                                    this.state.loggdin == 'start' ?
                                                                        <Alert variant="success">
                                                                            <Alert.Heading>Saved</Alert.Heading>
                                                                            <p>
                                                                                Information is saved as you submited
                                                                            </p>
                                                                        </Alert>

                                                                        :
                                                                        ''

                                                                }

                                                                <div className="form-row">
                                                                <div style={{ backgroundColor: '#69696A', padding: 20,
                                                                    width: '100%', borderRadius: 20, display: 'flex' }}>
                                                                    <div className="form-holder col-md-2" style={{ padding: 10 }}>
                                                                    <select onChange={this.handleQuestiontype}
                                                                            style={{ padding: 6, backgroundColor: '#f2f2f2', fontSize: 12 }}
                                                                            className="form-control"
                                                                        >
                                                                            <option value="" selected disabled>Select Question Type </option>
                                                                            <option value="Text">Text</option>
                                                                            <option value="Image">Image</option>
                                                                            <option value="Image and Text">Image and Text</option>
                                                                        </select>
<Button style={{ marginTop: 7, backgroundColor: '#ffffff', color: '#000', width: '100%' }}
onClick={this.handleClickQuestionimage.bind(this)}>
<IconButton color="primary" aria-label="upload picture"
component="span">
<ImageIcon />
</IconButton>
Image
</Button>
                                                                  
                                                                        <input id="questionfile" type="file" 
                                                                        onChange={this.fileSelectquestionimage.bind(this)} 
                                                                        accept="image/*"
                                                                        className='form-control'  
                                                                        ref={this.inputReference}  hidden 
                                                                        />
                                                                      
                                                                      
                                                                        {
                                                                        this.state.preview == '' ?
                                                                        ''
                                                                        :
                                                                        <img src={this.state.preview} style={{ width: 50, 
                                                                            height: 50, 
                                                                            marginTop: 7  }} /> 
                                                                        }
                                                                        {
                                                                            uploadPercentage > 0 && 
                                                                            <Progress bar color="warning" value={uploadPercentage}>{uploadPercentage}</Progress>
                                                                        }
                                                                   



                                                                    </div>

                                                                    <div className="form-holder col-md-9" style={{ padding: 10, borderWeight: 1,
                                                                    borderColor: 'silver' }}>
                                                                      {/*   <input type="textarea" 
                                                                        className="form-control form-control-user"
                                                                            id="exampleInputEmail" aria-describedby="emailHelp" 
                                                                            placeholder="Questional Title"
                                                                            value={this.state.questiontitle} 
                                                                            onChange={this.handlequestiontitle}
                                                                        /> */}
<div style={{ display: 'flex', marginBottom: 70 }}>
<EditableMathField
        latex={this.state.editorHtml}
        onChange={(mathField) => {
            this.setState({ editorHtml: mathField.latex()});
          }}        
        style={{ width: '100%', height: 100, padding: 10 }}

      />
<textarea onChange={(e) => this.setState({editorHtml: e.target.value})} value={this.state.editorHtml} 
style={{ width: '100%', display: 'none' }} />

{/* <ReactQuill  modules={modules} theme="snow" 
placeholder="Question Title"
style={{ width: '100%', fontSize: 18, color: '#ffffff' }}
onChange={this.handleChange}
value={this.state.editorHtml}
/>
 */}

</div>

</div>


                                                                    </div>
                                                                    <div className="form-holder col-md-1" style={{ padding: 10 }}>
                                                                      

{/* <Button type="button" className="btn btn-primary btn-user btn-block" onClick={this.testmath}>
Submit
</Button>  */}

                                                                    </div>

                                                                </div>
{/* <div className="form-row" style={{ height: 500 }}>
<Editor
editorState={this.state.editorState} 
 editorState={editorState} 
 toolbarClassName="toolbarClassName" 
wrapperClassName="wrapperClassName"
 editorClassName="editorClassName" 
onEditorStateChange={this.onChangeQuestionTitletextarea}
/>
</div> */}

                                                                <div className="form-row">
                                                                    <div className="form-holder col-md-3" style={{ padding: 10 }}>
                                                                        <div style={{ backgroundColor: '#00ADEF', padding: 10,
                                                                    width: '100%', borderRadius: 20 }}>

<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div>
            <select onChange={this.handleAtype}
            style={{ padding: 6, backgroundColor: '#f2f2f2', fontSize: 12, width: 60 }}
            className="form-control"
            >
            <option value="" selected disabled> Option </option>
            <option value="Text">Text</option>
            <option value="Image">Image</option>
            <option value="Image and Text">Image and Text</option>
            </select>
        </div>
        <div>
<Button style={{ backgroundColor: 'silver', color: '#000', width: 60, height: 33, padding: 0 }}
onClick={this.handleClickAimage.bind(this)}>
<ImageIcon />
{/* Image */}
</Button>
        <input type="file" id="Afiletype" onChange={this.fileSelectA} className='form-control'  
        ref={this.inputReferenceA} hidden
        
        />
        </div>
        <div>
            {
            this.state.previewA == '' ?
            <img class="img-profile rounded-circle" 
            src="/bootstrapcss/img/undraw_profile.svg" 
            style={{ width: 50, height: 50 }}></img>
            :
            <img src={this.state.previewA} style={{ width: 50, height: 50 }} /> 
            }
        </div>

</div>    
                                                                       {/*  <p style={{ color: '#000' }}>A</p> */}
                                                                      
                                                                       
                                                                        

<div style={{ display: 'flex', marginBottom: 50, marginTop: 10 }}>
{/* <ReactQuill  modules={modules} theme="snow" 
placeholder="A Title"
style={{ width: '100%', height: 150 }}
onChange={this.handleChangeA}
value={this.state.editorHtmlA}
/>
 */}
 <EditableMathField
        latex={this.state.editorHtmlA}
        onChange={(mathField) => {
            this.setState({ editorHtmlA: mathField.latex()});
          }}        
        style={{ width: '100%', height: 100, padding: 10 }}

      />
<textarea onChange={(e) => this.setState({editorHtmlA: e.target.value})} value={this.state.editorHtmlA} 
style={{ width: '100%', display: 'none' }} />


</div>
</div>
                                                                    </div>

                                                                    <div className="form-holder col-md-3" style={{ padding: 10 }}>
<div style={{ backgroundColor: '#EE5581', padding: 10,
 width: '100%', borderRadius: 20 }}>                                                                        
{/* <p style={{ color: '#000' }}>B</p> */}
<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div>
            <select onChange={this.handleBtype}
            style={{ padding: 6, width: 60, fontSize: 10 }}
            className="form-control"
            >
            <option value="" selected disabled> Option </option>
            <option value="Text">Text</option>
            <option value="Image">Image</option>
            <option value="Image and Text">Image and Text</option>
            </select>    
        </div>      
        <div>
<Button style={{ backgroundColor: 'silver', color: '#000', width: 60, height: 33, padding: 0 }}
onClick={this.handleClickBimage.bind(this)}>
<ImageIcon />
{/* Image */}
</Button>          
            
            
            <input type="file"  onChange={this.fileSelectB} className='form-control' 
             ref={this.inputReferenceB} hidden
            /> </div>
        <div>
            {
            this.state.previewB == '' ?
            <img class="img-profile rounded-circle" src="/bootstrapcss/img/undraw_profile.svg" style={{ width: 50, height: 50 }}></img>
            :
            <img src={this.state.previewB} style={{ width: 50, height: 50 }} /> 
            }    
        </div>                                                                  
</div>






<div style={{ display: 'flex', marginBottom: 50, marginTop: 10 }}>


<EditableMathField
        latex={this.state.editorHtmlB}
        onChange={(mathField) => {
            this.setState({ editorHtmlB: mathField.latex()});
          }}        
        style={{ width: '100%', height: 100, padding: 10 }}

      />
<textarea onChange={(e) => this.setState({editorHtmlB: e.target.value})} value={this.state.editorHtmlB} 
style={{ width: '100%', display: 'none' }} />


</div>
</div>


                                                                    </div>
                                                                    <div className="form-holder col-md-3" style={{ padding: 10 }}>
                                                                   {/*  <p style={{ color: '#000' }}>C</p> */}
                                                                   <div style={{ backgroundColor: '#204f7b', padding: 10,
                                                                    width: '100%', borderRadius: 20 }}>

<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div>
        <select onChange={this.handleCtype}
        style={{ padding: 6, width: 60, fontSize: 10 }}
        className="form-control"
        >
        <option value="" selected disabled> Option </option>
        <option value="Text">Text</option>
        <option value="Image">Image</option>
        <option value="Image and Text">Image and Text</option>
        </select>
        </div>
        <div>
<Button style={{ backgroundColor: 'silver', color: '#000', width: 60, height: 33, padding: 0 }}
onClick={this.handleClickCimage.bind(this)}>
<ImageIcon />
{/* Image */}
</Button>   

        <input type="file" onChange={this.fileSelectC} className='form-control'
         ref={this.inputReferenceC} hidden />



        </div>
        <div>
        {
        this.state.previewC == '' ?
        <img class="img-profile rounded-circle" src="/bootstrapcss/img/undraw_profile.svg" style={{ width: 50, height: 50 }}></img>
        :
        <img src={this.state.previewC} style={{ width: 50, height: 50 }} /> 
        }                                                                         
        </div>
</div>        
        <div style={{ display: 'flex', marginBottom: 50, marginTop: 10 }}>


{/*         <ReactQuill  modules={modules} theme="snow" 
        placeholder="C Title"
        style={{ width: '100%', height: 150 }}
        onChange={this.handleChangeC}
        value={this.state.editorHtmlC}
        />
 */}

<EditableMathField
        latex={this.state.editorHtmlC}
        onChange={(mathField) => {
            this.setState({ editorHtmlC: mathField.latex()});
          }}        
        style={{ width: '100%', height: 100, padding: 10 }}

      />
<textarea onChange={(e) => this.setState({editorHtmlC: e.target.value})} value={this.state.editorHtmlC} 
style={{ width: '100%', display: 'none' }} />




        </div>


</div>


                                                                    </div>
<div className="form-holder col-md-3" style={{ padding: 10 }}>
<div style={{ backgroundColor: '#FF933A', padding: 10, width: '100%', borderRadius: 20 }}>
{/* <p style={{ color: '#000' }}>D</p> */}
<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div>
            <select onChange={this.handleDtype}
            style={{ padding: 6, backgroundColor: '#f2f2f2', width: 60, fontSize: 10 }}
            className="form-control"
            >
            <option value="" selected disabled> Option </option>
            <option value="Text">Text</option>
            <option value="Image">Image</option>
            <option value="Image and Text">Image and Text</option>
            </select>
        </div>


        <div>
        <Button style={{ backgroundColor: 'silver', color: '#000', width: 60, height: 33, padding: 0 }}
        onClick={this.handleClickDimage.bind(this)}>
        <ImageIcon />
        {/* Image */}
        </Button>   

        <input type="file" onChange={this.fileSelectD} className='form-control' 
         ref={this.inputReferenceD} hidden/>
        </div>
       
        <div>
        {
        this.state.previewD == '' ?
        <img class="img-profile rounded-circle" src="/bootstrapcss/img/undraw_profile.svg" style={{ width: 50, height: 50 }}></img>
        :
        <img src={this.state.previewD} style={{ width: 50, height: 50 }} /> 
        } 
        </div>
</div>
<div style={{ display: 'flex', marginBottom: 50, marginTop: 10 }}>

<EditableMathField
        latex={this.state.editorHtmlD}
        onChange={(mathField) => {
            this.setState({ editorHtmlD: mathField.latex()});
          }}        
        style={{ width: '100%', height: 100, padding: 10 }}

      />
<textarea onChange={(e) => this.setState({editorHtmlD: e.target.value})} value={this.state.editorHtmlD} 
style={{ width: '100%', display: 'none' }} />


</div>

</div>
</div>






                                                                </div>


                                                                <div className="form-row">
                                                                <div className="form-holder col-md-3" style={{ padding: 10 }}>
                                                                {/*    <p> Answer</p> */}
                                                                   <select onChange={this.handleAnswer}
                                                                            style={{ padding: 6, backgroundColor: '#f2f2f2' }}
                                                                            className="form-control">
                                                                                 <option value="">Select Answer</option>
                                                                            <option value="A">A</option>
                                                                            <option value="B">B</option>
                                                                            <option value="C">C</option>
                                                                            <option value="D">D</option>
                                                                            <option value="No Answer">No Answer</option>
                                                                        </select>   
                                                                </div>
                                                                <div className="form-holder col-md-3" style={{ padding: 10 }}>  
                                                                <Button type="button" className="btn btn-primary btn-user btn-block" onClick={this.register}>
                                                                            Submit
                                                                        </Button> 
                                                                <input type="hidden" className="form-control form-control-user"
                                                                            id="exampleInputEmail" aria-describedby="emailHelp" 
                                                                            placeholder="Witting Answer"
                                                                            value={this.state.writtinganswer} onChange={this.handlewrittinganswer}
                                                                        />  
                                                                </div>  
                                                                </div>

                                                            </div>
                                                        </div>



                                                    </form>


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* /.container-fluid */}
                         </Container>

            </div>
        );

    }
}
export default Quizsetuptwo
