import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Toast from 'react-bootstrap/Toast';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import { useHistory } from 'react-router-dom';


const Studentheader = () => {
    const history = useHistory();



  useEffect(() => {
      checklogin();

  }, [])

const checklogin =() => {

}

    const flogout = () => {
        const token = localStorage.removeItem("token");
        const studentid = localStorage.removeItem("studentid");
        history.push('/');
      }

    return (
        <Container>
           <Container fluid>
           <Navbar expand="lg" fixed="top">
           
              <Navbar.Brand href="#">
              <Link to="/Studentdashboard">
                
                <img src='/images/logo.png' style={{ width: 105, marginLeft: 20 }}/>
                
                </Link>
              
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" 
              style={{ marginRight: 10 }} />
              <Navbar.Collapse id="navbarScroll" style={{ backgroundColor: '#d7dfd7' }}>
                <Nav
                  className="me-auto my-2 my-lg-0"
                  style={{ maxHeight: '100px', border:'none' }}
                  navbarScroll
                >

                </Nav>
                {/* <NavDropdown title="Menu" id="navbarScrollingDropdown" text-decoration style={{ marginRight: 100, marginLeft: 100 }}> */}
                    <NavDropdown.Item href="#action4" style={{ marginBottom: 10 }}>
                    <Link to="/Editstudentprofile" style={{ marginLeft: 20, marginBottom: 10, 
                      textDecoration: 'none', color: '#000' }}>
                      Profile
                      </Link> 
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action4" style={{ marginBottom: 10 }}>
                    <Link to="/Paymenthistory" style={{ marginLeft: 20, marginBottom: 10, 
                      textDecoration: 'none', color: '#000' }}>
                      Payment History
                      </Link> 
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action4" style={{ marginBottom: 10 }}>
                    <Link to="/Resulttransaction" style={{ marginLeft: 20, marginBottom: 10, 
                      textDecoration: 'none', color: '#000' }}>
                      Result
                      </Link> 
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                 
                    <NavDropdown.Item href="#action5"  onClick={flogout} style={{ marginLeft: 20, marginBottom: 10 }}>
                     Logout
                    </NavDropdown.Item>
                {/*   </NavDropdown> */}
{/*                   <Link to="/" className='btn' variant="outline-success"
                    style={{ backgroundColor: '#8854c0', color: '#ffffff' }}  onClick={flogout}>
                    LogOut</Link> */}
              </Navbar.Collapse>
           
          </Navbar>
           </Container>
        </Container>
    );
}


export default Studentheader;