import React, { useState, useEffect } from 'react';
import { Offline, Online } from "react-detect-offline";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert } from 'react-bootstrap'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Studentheader from './Studentheader';
import { Link } from 'react-router-dom';

import * as base from "../global";
import axios from 'axios';
import Examimage from './Examimage';
import Remaningquestion from './Remaningquestion'
import groupBy from 'json-groupby';


const Readmoredetails = (data) => {
  const [users, setUsers] = useState([])
  console.log('hhhh' + data.data)

  const getData = () => {
   // console.log(base.BASE_URL + '/subjectwisedata/' + localStorage.getItem('className') + '/' + localStorage.getItem('groupname') + '/' + localStorage.getItem('versionname') + '/' + data.data)
    axios.get(base.BASE_URL + '/subjectwisedata/' + localStorage.getItem('className') + '/' + localStorage.getItem('groupname') + '/' + localStorage.getItem('versionname') + '/' + data.data, {
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`
      }
    }).then(res => {

      let newdata = groupBy(res.data, ['chapter']);
     // console.log('new data......' + JSON.stringify(newdata))
      setUsers(newdata)
      window.scrollTo(0, 0);

    });
  }
  useEffect(() => {
    getData();
    //var after = groupBy(users, ['subjectname']);

  }, [])


  return (
    <Container style={{ marginTop: 63 }}>
      <Row>
        <div style={{
          justifyContent: 'space-between',
          width: '100%', display: 'flex', padding: 16, marginTop: 0, backgroundColor: '#f2f2f2',
          color: '#000'
        }} className='fixed-top'>
          
          <Link to="/Studentdashboard" style={{ color: '#000', textDecoration: 'none' }}>
            Back
          </Link>
          {data.data}
        </div>
      </Row>
      <Row>
        {Object.entries(users).map(([dep, staff]) => {
          return (
            <div>
              <div style={{
                justifyContent: 'space-between',
                width: '100%', display: 'flex', padding: 5, marginTop: 0 }}>
                {dep} <p><Link to={"/Readmorechapter/" + data.data + '/' + dep} className='readmore'>
                <img src='/images/small-arrow-icon-1.jpeg' style={{ width: 22 }}/>
                </Link></p>
              </div>
              <Row>

              </Row>
              <Row>
                {staff.slice(0, 2).map((result, slno) => {
                  return <Col xs={6} sm={2} key={slno}>
                    <Link to={"/Starttoprocesscourse/" + result._id} style={{ textDecoration: 'none' }}>
                      <Card style={{ marginBottom: 15 }}>
                        <Examimage data={result._id} />
                       {/*  <Card.Body>
                          <Card.Title style={{ textDecoration: 'none', fontSize: 12, color: '#000' }}>
                            {result.examname}</Card.Title>
                        </Card.Body> */}
                        <Card.Footer>
                          <Remaningquestion data={result._id} />
                        </Card.Footer>
                      </Card>
                    </Link>
                  </Col>

                })}
              </Row>
            </div>

          )
        }
        )
        }

      </Row>


    </Container>
  );
}


export default Readmoredetails;