import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

export class Header extends Component {
  constructor() {
    super();
    let loggdin = false;
    this.state = {
        userrole: localStorage.getItem("usersrole"), 
        emailaddress: localStorage.getItem("vendoremailaddress"),
        logout: false
    }

  }
  componentDidMount(){
   // alert(this.state.userrole)
  }
  flogout = () => 
  {
    localStorage.removeItem("token");
    localStorage.removeItem("emailaddress");
    localStorage.removeItem("vendoremailaddress");
    this.setState({ logout: true })
    window.location.reload()
  }

  render() {
    if (this.state.logout == true) {
        return <Redirect to="/" />
      }
    
    return (
          <Navbar expand="lg" fixed="top" style={{ backgroundColor: '#ffffff' }}>
            <Container fluid>
              <Navbar.Brand href="/">
                {/* <h3 style={{ color: '#5D1F57' }}>examamine</h3> */}
                <img src='/images/logo.png' style={{ width: 78, height: 58, marginLeft: 20 }}/>
                </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" style={{ marginRight: 20 }}/>
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="me-auto my-2 my-lg-0"
                  style={{ maxHeight: '100px' }}
                  navbarScroll
                >
{/*                   <NavDropdown title="Quiz" id="navbarScrollingDropdown">
                    <NavDropdown.Item href="#action3"> MCQ Question </NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Math Question
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Fill in the blanks
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action5">
                      Writting Exams
                    </NavDropdown.Item>
                  </NavDropdown> */}
{/*                   <Nav.Link href="/Teacher" style={{ marginLeft: 20 }}>
                    For Teacher
                  </Nav.Link>
                  <Nav.Link href="/Students" style={{ marginLeft: 20 }}>
                    For Student
                  </Nav.Link> */}
                </Nav>
                
                  {
                    this.state.emailaddress == null
                    ?
                    <Form className="d-flex">
                  {/*   <Link to="/Studentlogin"
                      variant="outline-success" className='btn'
                      style={{ backgroundColor: '#ffffff', color: '#000', marginRight: 5, borderWidth: 2, borderColor: '#000' }}>
                      Enter Code ...{this.state.emailaddress}
                    </Link> */}
                   {/*  <Link to="/Signinwithaccount" className='btn'
                      style={{ backgroundColor: '#ffffff', color: '#000', 
                      marginRight: 5, borderWidth: 2, borderColor: '#000', marginLeft: 20 }}>
                      Sign In</Link>
                    <Link to="/Signupwithaccount" className='btn' variant="outline-success"
                      style={{ backgroundColor: '#8854c0', color: '#ffffff', marginLeft: 20 }}>
                      Sign Up</Link> */}
                  </Form>
                    :
                    <Form className="d-flex">
                      {
                        this.state.userrole == "Teacher" ?
                        <Link to="/Dashboard" className='btn' variant="outline-success"
                        style={{ backgroundColor: '#8854c0', color: '#ffffff', marginRight: 10 }}>
                        Dashboard</Link>
                        :
                       ''
                      }
                      {
                        this.state.userrole == "Student" ? //Studentdashboard
                        <Link to="/Studentdashboard" className='btn' variant="outline-success"
                        style={{ backgroundColor: '#8854c0', color: '#ffffff', marginRight: 10 }}>
                        Student Dashboard</Link>
                        :
                        ''
                      }
                      {
                         this.state.userrole == "Super Admin" ?
                         <Link to="/Dashboard" className='btn' variant="outline-success"
                         style={{ backgroundColor: '#8854c0', color: '#ffffff', marginRight: 10 }}>
                         Dashboard</Link>
                         :
                         ''

                      }
                    
                     <Link to="#" className='btn' variant="outline-success"
                      style={{ backgroundColor: '#8854c0', color: '#ffffff' }} onClick={this.flogout}>
                      Logout</Link>
                    </Form>
                  }

              </Navbar.Collapse>
            </Container>
          </Navbar>
    )
  }
}

export default Header