import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
//import useForm from 'react-hook-form'
import { Button, Form } from 'reactstrap';
import * as base from "../global";
import axios from 'axios';
import Header from '../Header'
import Footer from '../Footer'
import ActivityIndicator from 'react-activity-indicator'

import 'react-activity-indicator/src/activityindicator.css'


export class Forgetpassword extends Component {

  constructor() {
    super();
    this.register = this.register.bind(this);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      Password: '',
      isLoading: false,
      loaderfile: 'notloading',
      fields: {},
      errors: {},
      loggdin: 'stop',
      counter: 0, showPassword: false,
    }


  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //  alert(this.state.fields["email"]);
    //Name
    if (!this.state.fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password Reqired ";
    }
    if (!this.state.fields["email"]) {
      formIsValid = false;
      errors["email"] = "Student ID Required";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  register() {
    if (this.handleValidation()) {
      this.setState({ loaderfile: 'loading', isLoading: true }) ///Adminloginareacheck  adminlogin Adminloginsetup
      axios.post(base.BASE_URL + '/studentlogin', {
        email: this.state.fields["email"],
        password: this.state.fields["password"]
      })
        .then((response) => {
          if (response.data.message == "found") {
            //  alert(response.data.classname)
            localStorage.setItem("token", response.data.access_token);
            localStorage.setItem("studentid", this.state.fields["email"]);
            localStorage.setItem("fullname", response.data.fullname);
            localStorage.setItem("examid", response.data.examid);
            localStorage.setItem("className", response.data.classname);
            localStorage.setItem("groupname", response.data.groupname);
            localStorage.setItem("versionname", response.data.versionname);

            this.setState({ loggdin: 'start', loaderfile: 'notloading', isLoading: false })

          }
          else if (response.data == "Unauthorized") {
            this.setState({ loggdin: 'stop', isLoading: false })
            alert("Invalid Email and Password");
          }
          else if (response.data == "Unauthorized f") {

            this.setState({ loggdin: 'stop', isLoading: false })
            alert("Invalid Email and Password");

          }


        }, (error) => {
          console.log(error);
          alert(error)
        });

    } else {
      // alert("Form has errors.")
    }


  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handleClickShowPassword = () => {
    // setValues({ ...values, showPassword: !values.showPassword });
    // alert(this.state.showPassword)
    if (this.state.showPassword == false) {
      this.setState({
        showPassword: true
      })
    }
    else {
      this.setState({
        showPassword: false
      })
    }

  };

  render() {
    // alert(this.state.loggdin);
    if (this.state.loggdin == 'start') {
      return <Redirect to="/Studentdashboard" />
    }
    return (
      <div>
        <Header />
        <div className="container" style={{ marginTop: 86, padding: 32 }}>

                  {/* Nested Row within Card Body */}
                  {
                    this.state.isLoading == true ?
                      <ActivityIndicator />
                      :
                      <div className="row">
                            <div className="text-center">

                              <p className="studentlogin">Forget Password</p>
                              {/* <Alert variant="success">Data is saved sucessfully</Alert>   */}
                            </div>
                            <form className="user text-center">
                              <div className="form-group">
                                <input type="email" className="logintext"
                                  placeholder="Enter Email Address"
                                  onChange={this.handleChange.bind(this, "email")} 
                                  value={this.state.fields["email"]}
                                />
                                <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                              </div>
      
                              <Button type="button" className="loginbtn" onClick={this.register}>
                                Submit
                              </Button>
                            </form>
                      
                            <div className="text-center" style={{ marginTop: 17 }}>
                              <Link to="/Continuewithstudent" className="forgetpassword-text" href="#" >Login ?</Link>
                            </div>
                      </div>
                  }


                </div>  </div>
           
    )

  }
}

export default Forgetpassword