import React, { useState, useEffect } from 'react';
import { Offline, Online } from "react-detect-offline";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert } from 'react-bootstrap'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Studentheader from './Studentheader';
import Footer from '../Footer';
import { Link, useHistory } from 'react-router-dom';

import Readmoredetailschapter from './Readmoredetailschapter'
import * as base from "../global"; 
import axios from 'axios';
import Examimage from './Examimage';
import groupBy from 'json-groupby';
import Remaningquestion from './Remaningquestion'



const Readmorechapter = (props) => {
  const history = useHistory()
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [users, setUsers] = useState([]);
  const [studentid, setStudentid] = useState(localStorage.getItem('studentid'))
  const [classname, setClassname] = useState(localStorage.getItem('className'))
  const [subject, setSubject] = useState(props.match.params.id);
  const [chapter, setchapter] = useState(props.match.params.ids);

  const getData = () => {
   console.log(base.BASE_URL + '/subjectwisedatachapter/' + localStorage.getItem('className') + '/' + localStorage.getItem('groupname') + '/' + localStorage.getItem('versionname') + '/' + subject + '/' + chapter)
   axios.get(base.BASE_URL + '/subjectwisedatachapter/' + localStorage.getItem('className') + '/' + localStorage.getItem('groupname') + '/' + localStorage.getItem('versionname') + '/' + subject + '/' + chapter, {
     headers: {
       authorization: `bearer ${localStorage.getItem('token')}`
     }
   }).then(res => {

   //  let newdata = groupBy(res.data, ['chapter']);
   //  console.log('new data......' + JSON.stringify(newdata))
     setUsers(res.data)
     window.scrollTo(0, 0);

   });
 }
 useEffect(() => {
   getData();
 
 }, [])

  return (
   <Container style={{ marginTop: 60 }}>
      <Row>
        <div style={{
          justifyContent: 'space-between',
          width: '100%', display: 'flex', 
          padding: 16, marginTop: 0, 
          backgroundColor: '#f2f2f2',
          color: '#000'
        }} className='fixed-top'>
          {subject} {/* .....{chapter} */}
          <Link to={"/Readmore/" + subject} style={{ color: '#000' }}>
            Back
          </Link>
        </div>
      </Row>
      <Row>
            {
            users ?
               users.map((result, slno) => (

                   <Col xs={6} sm={2} key={slno}>
                    <Link to={"/Starttoprocesscourse/" + result._id} 
                    style={{ textDecoration: 'none' }}>
                      <Card style={{ marginBottom: 15 }}>
                        <Examimage data={result._id} />
                    {/*     <Card.Body>
                          <Card.Title style={{ textDecoration: 'none', fontSize: 12, color: '#000' }}>
                           {result.examname}
                           </Card.Title>
                        </Card.Body> */}
                        <Card.Footer>
                          <Remaningquestion data={result._id} />
                        </Card.Footer>
                      </Card>
                    </Link>
                  </Col>

)) : "Loading..."
}

      </Row>


    </Container>
  );
}


export default Readmorechapter;
