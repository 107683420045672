import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import * as base from "./global";
import { Redirect } from 'react-router';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import 'bootstrap/dist/css/bootstrap.min.css';
import Toast from 'react-bootstrap/Toast';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export class Signinwithaccount extends Component {
  constructor() {
    super();
    let loggdin = false;
    this.state = {
      email: '',
      Password: '',
      isLoading: false,
      fields: {},
      errors: {},
      datauserprofile: [],
      image: 'maleavter.png'
    }
  }
  componentDidMount() {
    const formdata = {
      pagetype: "Homepage"
    }
    axios.post(base.BASE_URL + "/visitor", formdata, {
    }).then(res => {
      console.log('data..file..' + res.data);
    })

  }

  render() {
    return (
      <div>
        <Container style={{ height: '100vh' }}>
          <Header />

          <div style={{ padding: 100, flexDirection: 'row', textAlign: 'center' }}>

            <Row>
              <Col sm={6}>
                <Link to="/Login"
                   className='grey-button'>
                  Teacher Sign In
                </Link>
              </Col>
              <Col sm={6}>
                <Link
                  to="/Studentlogin"
                  className='grey-button' style={{ marginTop: 10 }}>
                  Student
                  Sign In
                </Link>
              </Col>
            </Row>


          </div>
        </Container>
      </div>
    )
  }
}

export default Signinwithaccount