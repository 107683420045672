import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

export class Footer extends Component {
  constructor() {
    super();
    let loggdin = false;
    this.state = {
        userrole: localStorage.getItem("usersrole"), 
        emailaddress: localStorage.getItem("vendoremailaddress"),
        logout: false
    }

  }
  componentDidMount(){
    //alert(this.state.emailaddress)
  }
  flogout(){
    const token = localStorage.removeItem("token");
    const emailaddress = localStorage.removeItem("emailaddress");
    const vendoremailaddress = localStorage.removeItem("vendoremailaddress");
    this.setState({ logout: true })
  }

  render() {
    if (this.state.logout == true) {
        return <Redirect to="/" />
      }
    
    return (
        <div style={{ width: '100%', height: 200, backgroundColor: '#f1f1f1'}}>
        <div className='divcenter'>
            <Row>
                <Col sm={4}>
                  <ul style={{ marginTop: 50 }}>
                    <li>
                    <Link to="" className='footer-links'>About Us</Link>
                    </li>
                    <li>
                    <Link to="" className='footer-links'>Help Center</Link>
                    </li>
                    <li>
                    <Link to="" className='footer-links'>Customer Care</Link>
                    </li>
                    <li>
                    <Link to="" className='footer-links'>Quiz</Link>
                    </li>
                  </ul>
                
                </Col>
                <Col sm={4}>
                <ul style={{ marginTop: 50 }}>
                    <li>
                    <Link to="" className='footer-links'>Terms of Service</Link>
                    </li>
                    <li>
                    <Link to="" className='footer-links'>Privacy Policy </Link>
                    </li>
                    <li>
                    <Link to="" className='footer-links'> Careers</Link>
                    </li>
                    <li>
                    <Link to="" className='footer-links'>About Us</Link>
                    </li>
                  </ul>
                
                </Col>
                <Col sm={4}>
                <ul style={{ marginTop: 50 }}>
                    <li className='footer-text'>
                    Download Examamine mobile apps
                    </li>
                    <li>
                    <a href="https://play.google.com/store/apps/details?id=com.examamineapps" target="_blank">
                   <img src="https://quizizz.com/wf/assets/62fa641a161d3a2982681d00_Google_Play.svg" 
                   loading="lazy" style={{ marginTop: 10 }} alt="Get it on Google Play Badge" classname="download-image" 
                   />
                    </a>
                    </li>
                  </ul>
                
                </Col>
            </Row>
        </div>
           
        </div>
    )
  }
}

export default Footer