import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
//import useForm from 'react-hook-form'
import { Button, Form } from 'reactstrap';
import * as base from "./global";
import axios from 'axios';
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Topbar from '../Layout/Topbar'
import Container from 'react-bootstrap/Container';
//https://stackoverflow.com/questions/41296668/reactjs-form-input-validation
import ActivityIndicator from 'react-activity-indicator'
//npm install react-activity-indicator
import 'react-activity-indicator/src/activityindicator.css'


export class Userlist extends Component {

    constructor() {
        super();

        this.state = {
            name: '',
            address: '',
            email: '',
            phone: '',
            id: '',
            website: '',
            isLoading: false,
            isdelete: false,
            loaderfile: 'notloading',
            fields: {},
            errors: {},
            loggdin: 'stop',
            counter: 0,
            allsubscribelist: []
        }


    }
    componentDidMount() {
      this.getData();
    }
    getData =() => {
        axios.get(base.BASE_URL + '/userslist', {
            headers: {
                authorization: `bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            console.log(res.data)
            this.setState({ allsubscribelist: res.data });
        });
    }

 
    deleteUser(id) {
        var answer = window.confirm("Are you sure you want to delete ?");
        if (answer) {
            this.setState({ isdelete: true })
            // true (paypal.me/andrewdhyder)
            axios.post(base.BASE_URL + '/delete_users', {
                id: id
                },{
                    headers: {
                        authorization: `bearer ${localStorage.getItem('token')}`
                    } 
                })
                .then((response) => {

                if (response.data == "delete") {
                    this.getData();
                        this.setState({ isdelete: false })
                
                }
                else if (response.data == "notfound") {

                }
                }, (error) => {
                console.log(error);
                });
          } else {
///////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
          }

        ////////////////////////////////////////////////////////////////////
 
        ////////////////////////////////////////////////////////////////////
    }
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    render() {
        // alert(this.state.loggdin);
        if (this.state.loggdin == 'start') {
            return <Redirect to="/Dashboard" />
        }

        return (
            <div>

                        <Header /> {/* Slider Menu */}
                        <Container style={{ marginTop: 86 }}>
                                    <div className="row">
                                        <div className='col-md-12 firstcolumn'>
                                            <div className='secondcolumn'>
                                                <Link to="" style={{ padding: 30 }}>List of users</Link>
                                            </div>
                                            <div className='thirdcolumn'>
                                                <Link to="/Userssetup" className='btn btn-primary'
                                                    style={{ marginBottom: 20 }}
                                                >
                                                    Add New
                                                </Link>
                                            </div>
                                        </div>

                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Instituted Name</th>
                                                    <th>Action</th>
                                                    <th>Delete</th>

                                                </tr> 
                                            </thead>
                                            <tbody>
                                                {this.state.allsubscribelist.map((result) => {
                                                    return (
                                                        <tr key={result.email}>
                                                            <td>{result.fullname}</td>
                                                            <td>{result.email}</td>
                                                            <td>{result.schoolcollegename}</td>
                                                            <td>
                                                                <Link variant="info" to={"/Edituserssetup/" + result._id}>Edit</Link>
                                                            </td>
                                                            <td>
                                                                <Button variant="danger" onClick={() => this.deleteUser(result._id)}>Delete</Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>


                                    </div>

                            </Container>

            </div>
        );

    }
}
export default Userlist
