import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import * as base from "./global";
import { Redirect } from 'react-router';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import Easytoacton from './Easytoacton'
import Homebanner from './Homebanner'
import Quicklyfinder from './Quicklyfinder'
import 'bootstrap/dist/css/bootstrap.min.css';
import Toast from 'react-bootstrap/Toast';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRunning, faFlag, faManatSign } from '@fortawesome/free-solid-svg-icons'
import Engageevery from './Engageevery';

export class Home extends Component {
  constructor() {
    super();
    let loggdin = false;
    this.state = {
      email: '',
      Password: '',
      isLoading: false,
      fields: {},
      errors: {},
      datauserprofile: [],
      image: 'maleavter.png',
      studentid : localStorage.getItem('studentid')
    }
  }
  componentDidMount() {
   // alert(this.state.studentid)
    /*     const formdata = {
          pagetype: "Homepage"
        }
        axios.post(base.BASE_URL + "/visitor", formdata, {
        }).then(res => {
          console.log('data..file..' + res.data);
        })
     */
  }

  render() {

    if (this.state.studentid) {
      return <Redirect to="/Studentdashboard" />
    }
    return (
      <div style={{ backgroundColor: '#fff' }}>
        {/*   <Header /> */}

        <div className="container text-center" style={{ padding: 32 }}>

          <div className="bodymobile" style={{
            alignItems: 'center',
            justifyContent: 'center', backgroundColor: '#fff',
            height: '100vh',
          }}>
            <div style={{ height: '70%' }}>
              <div style={{ width: '100%', height: 262, marginBottom: 10 }}>

                <img src='/images/logo-self.png' style={{ resize: 'cover' }} />

              </div>
            </div>

            <div style={{ width: '100%', height: 50, marginBottom: 10 }}>

              <Link to="/Continuewithteacher"
                className='continuewithteacher btn'>
                Continue as a Techer
              </Link>

            </div>
            <div style={{ width: '100%', height: 50 }}>

              <Link to="/Continuewithstudent"
                className='continuewithstudent btn'>
                Continue as a Student
              </Link>
            </div>


          </div>

        </div>
      </div>
    )
  }
}

export default Home