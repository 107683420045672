
//export const BASE_URL = "http://localhost:8081"; 

//export const BASE_URL = "http://103.36.103.141:8080"; // live server
export const BASE_URL = "https://api.examamine.com"; // live server
//export const BASE_URL = "http://192.168.0.109:3000"; // live server

//export https://api.examamine.com/
export const IMAGE_URL = "https://examamine.com/file/"; 
//export const IMAGE_URL = "https://examamine.com/file/"; 
